import React from 'react'
import  { useRoutes } from 'react-router-dom'
import { developerCenterRoutes } from './views'
import { MainLayout } from './layout'

export function GlobalRoutes (){
  const globalRoutes = useRoutes([
    {
      path: '/',
      element: <MainLayout/>,
      children: [
        ...developerCenterRoutes
      ]
    }
  ])

  return globalRoutes
}
