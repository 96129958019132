interface configType {
  protocol: string,
  openapi: string,
  apiGetway: string,
  login: string,
  logout: string,
  refreshToken: string,
  prefix: string,
}

interface envConfigType {
  local: configType,
  dev: configType,
  staging: configType,
  release: configType,
  prod: configType
}


export type nodeEnvType = 'local' | 'dev' | 'staging' | 'release' | 'prod';

export const nodeEnv = (process.env.REACT_APP_NODE_ENV ? process.env.REACT_APP_NODE_ENV : 'local') as nodeEnvType
export const region = process.env.REACT_APP_REGION;
const regionValue = region != 'undefined' ? `.${region}` : '';
export const envConfig : envConfigType =  {
  local: {
    protocol: '',
    openapi: '',
    apiGetway: '',
    login: `https://bot${regionValue}.staging.gs-robot.com/gas/api/v1alpha1/sso/login`,
    logout: `https://bot${regionValue}.staging.gs-robot.com/gas/api/v1alpha1/sso/logout`,
    refreshToken: `https://bot${regionValue}.staging.gs-robot.com/gas/api/v1alpha1/auth/token`,
    prefix: "https://cloud.gs-robot.com",
  },
  dev: {
    protocol: 'https://',
    openapi: `openapi${regionValue}.dev.gs-robot.com`,
    apiGetway: `bot${regionValue}.dev.gs-robot.com`,
    login: `https://bot${regionValue}.dev.gs-robot.com/gas/api/v1alpha1/sso/login`,
    logout: `https://bot${regionValue}.dev.gs-robot.com/gas/api/v1alpha1/sso/logout`,
    refreshToken: `https://bot${regionValue}.dev.gs-robot.com/gas/api/v1alpha1/auth/token`,
    prefix: "cloud.dev.gs-robot.com",
  },
  staging: {
    protocol: 'https://',
    openapi: 'openapi.staging.gs-robot.com',
    apiGetway: 'bot.staging.gs-robot.com',
    login: 'https://bot.staging.gs-robot.com/gas/api/v1alpha1/sso/login',
    logout: 'https://bot.staging.gs-robot.com/gas/api/v1alpha1/sso/logout',
    refreshToken: 'https://bot.staging.gs-robot.com/gas/api/v1alpha1/auth/token',
    prefix: "cloud.staging.gs-robot.com",
  },
  release: {
    protocol: 'https://',
    openapi: 'openapi.release.gs-robot.com',
    apiGetway: 'bot.release.gs-robot.com',
    login: 'https://bot.release.gs-robot.com/gas/api/v1alpha1/sso/login',
    logout: 'https://bot.release.gs-robot.com/gas/api/v1alpha1/sso/logout',
    refreshToken: 'https://bot.release.gs-robot.com/gas/api/v1alpha1/auth/token',
    prefix: "cloud.release.gs-robot.com",
  },
  prod: {
    protocol: 'https://',
    openapi: `openapi${regionValue}.gs-robot.com`,
    apiGetway: `bot${regionValue}.gs-robot.com`,
    login: `https://bot${regionValue}.gs-robot.com/gas/api/v1alpha1/sso/login`,
    logout: `https://bot${regionValue}.gs-robot.com/gas/api/v1alpha1/sso/logout`,
    refreshToken: `https://bot${regionValue}.gs-robot.com/gas/api/v1alpha1/auth/token`,
    prefix: "cloud.gs-robot.com",
  }
}
