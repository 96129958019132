import { request } from "../request";
import { envConfig, nodeEnv } from '../../envConfig';

export function getAccessKeyManagementList(): Promise<AccessKeyManagementType.AccessKeyManagementListRes> {
  return request(
    '/v1alpha1/openAccessKeys',
    {page: 1, pageSize: 100},
    {
      method: 'GET',
      host: envConfig[nodeEnv].openapi
    }
  )
}

export function CreateAccessKey(params: AccessKeyManagementType.CreateAccessKeyManagementParam): Promise<AccessKeyManagementType.CreateAccessKeyManagementRes> {
  return request(
    '/v1alpha1/openAccessKeys',
    params,
    {
      method: 'POST',
      host: envConfig[nodeEnv].openapi
    }
  )
}

export function deleteAccessKey(params: any) {
  return request(
    `/v1alpha1/openAccessKeys/delete`,
    params,
    {
      method: 'POST',
      host: envConfig[nodeEnv].openapi
    }
  )
}
