import { atom } from "recoil";

// 由于登陆在其他项目中，这里用来标记应用的初始化工作全部完成（获取用户信息
export const appReady = atom({
  key: "appReady",
  default: false
})

export const userInfo = atom({
  key: "userInfo",
  default: {} as UserType.User,
});