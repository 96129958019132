import { request } from "../request";
import { envConfig, nodeEnv } from '../../envConfig';

export function getAppManagementList(): Promise<AppManagementType.AppManagementListRes> {
  return request(
    '/v1alpha1/clients',
    {page: 1, pageSize: 100},
    {
      method: 'GET',
      host: envConfig[nodeEnv].openapi
    }
  )
}

export function CreateAppManagement(params: AppManagementType.CreateAppManagementParam): Promise<AppManagementType.CreateAppManagementRes> {
  return request(
    '/v1alpha1/clients',
    params,
    {
      method: 'POST',
      host: envConfig[nodeEnv].openapi
    }
  )
}

export function deleteAppManagement(params: any) {
  return request(
    `/v1alpha1/clients/delete`,
    params,
    {
      method: "POST",
      host: envConfig[nodeEnv].openapi
    } 
  )
}
