import { request } from "../request";

export function getToken(ticket:string):Promise<any>{
  return request(
    '/gas/api/v1alpha1/sso/token',
    {
      ticket,
      legacy: true,
    },
    {
      method: 'GET',
      withCredentials: true,
    }
  )
}

export function getUserInfo():Promise<any>{
  return request(
    '/cedar/v1alpha1/tenants/context',
    {},
    {
      method: 'GET',
    }
  )
}

export function refreshAccessToken(){
  return request(
    '/gas/api/v1alpha1/auth/token',
    {
      refreshToken: localStorage.getItem('reach_refreshToken'),
    }
  )
}