import AppManagement from "./appManagement"
import AccessKeyManagement from "./accessKeyManagement"
export const developerCenterRoutes = [
  {
    path: '/developerCenter/appManagement',
    element: <AppManagement/>,
  },
  {
    path: '/developerCenter/accessKeyManagement',
    element: <AccessKeyManagement/>,
  }
]