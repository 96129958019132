import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Result, message } from 'antd';
import './appManagement.less';
import { getAppManagementList, CreateAppManagement, deleteAppManagement } from '../../../api'
import { useRequest, useUpdateEffect } from 'ahooks'
import { useRecoilValue } from 'recoil'
import { userInfo as recoilUserInfo, appReady } from '../../../recoil'
import moment from 'moment';
import type { FormValuesType } from './AppManagementData';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useI18nHook } from "../../../i18n"


function AppManagement() {
  const [data, setData] = useState<Array<AppManagementType.AppManagement>>([])
  const [createModalVisible, setCreateModalVisible] = useState(false)
  const userInfo = useRecoilValue(recoilUserInfo);
  const appIsReady = useRecoilValue(appReady);
  const [secretDownloadVisible, setSecretDownloadVisible] = useState(false)
  const [secretID, setSecretID] = useState<string>('')
  const [secret, setSecret] = useState<string>('')
  const [displayName, setDisplayName] = useState<string>('secret')
  // 国际化
  const i = useI18nHook()

  const { run: runCreateAccessKey } = useRequest(CreateAppManagement, {
    manual: true,
    onSuccess: (data) => {
      setSecret(data.secret)
      setSecretID(data.id)
      setSecretDownloadVisible(true)
    },
    onError: (err) => {
      console.log('error')
      console.log(err)
      message.error(i('创建失败！'))
    }
  });


  useEffect(() => {
    if (userInfo && userInfo.person)
      runGetAppManagementList()
  }, []);

  useUpdateEffect(() => {
    if (appIsReady)
      runGetAppManagementList()
  }, [appIsReady]);

  const { run: runGetAppManagementList, loading } = useRequest(getAppManagementList, {
    manual: true,
    onSuccess: (res) => {
      if (res) {
        setData(res.clients)
      }
    }
  })

  const onFinish = (values: FormValuesType) => {
    setCreateModalVisible(false)
    setDisplayName(values.displayName)
    runCreateAccessKey({
      ...values,
      grantType: "OPEN_ACCESS_KEY"
    })
  };

  const downloadTxt = (text: string, fileName: string) => {
    let element = document.createElement('a')
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
    element.setAttribute('download', fileName)
    element.style.display = 'none'
    element.click()
  }

  const handleCloseSecretDownload = () => {
    Modal.confirm({
      title: i('确定要关闭吗'),
      icon: <ExclamationCircleOutlined />,
      content: i('我们不会保存您的secret请确保已经备份，否则将无法恢复'),
      okText: i('确定'),
      cancelText: i('取消'),
      onOk() {
        runGetAppManagementList()
        setSecretDownloadVisible(false)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const SecretDownload = () => {
    return (
      <Modal title={i("创建成功")}visible={secretDownloadVisible} closable={false} footer={false}>
        <Result
          status="warning"
          title={i("创建成功！请在下方链接下载您的secret")}
          subTitle={i("请注意我们不会保存您的secret，下方链接是您唯一获取secret的方式，请妥善保管！")}
          extra={
            <div>
              <Button danger style={{ marginBottom: '10px' }} onClick={() => { downloadTxt(`ClientID, ClientSecret\r\n${secretID},${secret}`, displayName) }}><span>{i('下载secret')}</span></Button>
              <br />
              <Button type="primary" onClick={handleCloseSecretDownload} key="console">
                {i('关闭')}
              </Button>
            </div>
          }
        />,
      </Modal>
    )
  }

  const CreateAccessKeyModal = () => {
    return (
      <Modal title={i("创建应用")} maskClosable={false} visible={createModalVisible} footer={false} onCancel={() => { setCreateModalVisible(false) }}>
        <Form
          name="basic"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          labelWrap={true}
        >
          <Form.Item
            label={i("名称")}
            name="displayName"
            rules={[
              { required: true, message: i('请填写当前创建的应用名称！') },
              { pattern: /\S/, message: i('请填写当前创建的应用名称！') }
            ]}
          >
            <Input showCount maxLength={100}/>
          </Form.Item>

          <Form.Item
            label={i("描述")}
            name="description"
            rules={[
              { required: true, message: i('请填写当前创建的AccessKey的描述！') },
              { pattern: /\S/, message: i('请填写当前创建的AccessKey的描述！') }
            ]}
          >
            <Input showCount maxLength={50} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 11, span: 13 }}>
            <Button type="primary" htmlType="submit">
              {i("提交")}
            </Button>
          </Form.Item>
        </Form>
      </Modal >
    )
  }

  const onDelete = async (clientId: string) => {
    try {
      const res = await deleteAppManagement({clientId})
      if(res.code === 0) {
        message.success(i("删除成功"))
        runGetAppManagementList()
      } else {
        const errorCodes: any = {
          13: i("服务异常，请重试"),
          20000: i("无法删除非自己创建的应用")
        }
        message.error(errorCodes[res.code] || "出错啦")
      }
    } catch (error) {
      message.error(i(`${i("删除失败")}`))
    }
  }

  const confirmDelete = (openAccessKeyId: string) => {
    Modal.confirm({
      title: i("提示"),
      content: i("您是否确认删除，会影响已有的开发对接成果？"),
      onOk: () => onDelete(openAccessKeyId),
      okText: i("确定"),
      cancelText: i("取消"),
      onCancel: () => Modal.destroyAll()
    })
  }

  const columns: any = [
    {
      title: i('名称'),
      dataIndex: 'displayName',
    },
    {
      title: i('描述'),
      dataIndex: 'description',
    },
    {
      title: i('应用ID'),
      dataIndex: 'id',
      width: '350px',
    },
    {
      title: i('创建时间'),
      dataIndex: 'createTime',
      width: '200px',
      render: (text: string)=>{
        return (
          <>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</>
        )
      }
    },
    {
      title: i("操作"),
      key: "operation",
      width: "200px",
      fixed: 'right',
      render: (row: any) => {
        return (
          <>
            <Button type='link' onClick={() => confirmDelete(row.id)}>{i("删除")}</Button>
          </>  
        )
      }
    }
  ];

  const handleCreate = () => {
    setCreateModalVisible(true)
  }

  return (
    <div className='main'>
      <SecretDownload />
      <CreateAccessKeyModal />
      <div style={{ marginBottom: 16 }}>
        <Button onClick={handleCreate} style={{ marginRight: '5px' }} type="primary">
          {i("创建")}
        </Button>
        {/* <Button onClick={() => { setSecretDownloadVisible(true) }} style={{ marginRight: '5px' }} type="primary">
          test
        </Button> */}
        <Button onClick={runGetAppManagementList} type="primary">
          {i("刷新列表")}
        </Button>
      </div>
      <Table rowKey='id' columns={columns} loading={loading} dataSource={data} pagination={false} scroll={{ y: 999999 }} />
    </div>
  );
}

export default AppManagement