import { atom, selector } from 'recoil';
// import { message } from 'antd';
import enUS from "../../i18n/en-US.json";
import zhCN from "../../i18n/zh-CN.json";
import koKR from "../../i18n/ko-KR.json";
import plPL from "../../i18n/pl-PL.json";

const LANGUAGE_MAP: Record<string, any> = {
  "zh-CN": zhCN,
  "en-US": enUS,
  "ko": koKR,
  "pl": plPL,
}

export const language = atom({
  key: 'language',
  default: localStorage.getItem('locale') || "zh-CN",
});


export const getLanguageJson = selector({
  key: 'getLanguageJson',
  get: async ({ get }) => {
    const local = get(language);
    return LANGUAGE_MAP[local];
  },
});
