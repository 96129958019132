import { getLanguageJson } from '../recoil';
import { useRecoilValue } from 'recoil';

type transFunc = (key: string) => string;

export function useI18nHook(): transFunc {
  const languageJson = useRecoilValue<{[key:string]:string}>(getLanguageJson);

  return (key: string) => {
    if (!languageJson || !languageJson[key]) {
      return key;
    }

    return languageJson[key];
  };
}
